.bubble-container{
    margin-top: 8px;
    margin-bottom: 8px;
    display:flex;
    font-family: source sans pro light;
    font-size: 14px;
    align-items: center;
  }
  
  .bubble-direction-reverse {
    flex-direction: row-reverse;
  }
  
  .bubble{
      background-color: #e60000;
      border-radius: 5px;
      box-shadow: 0 0 6px #e5186b;
      display: block;
      padding: 10px 18px;
      position: relative;
      color: white;
      word-wrap: break-word;
  }
  
  .bubble::before {
      background-color: #F2F2F2;
      content: "\00a0";
      display: block;
      height: 16px;
      position: absolute;
      top: 11px;
      transform:             rotate( 29deg ) skew( -35deg );
          -moz-transform:    rotate( 29deg ) skew( -35deg );
          -ms-transform:     rotate( 29deg ) skew( -35deg );
          -o-transform:      rotate( 29deg ) skew( -35deg );
          -webkit-transform: rotate( 29deg ) skew( -35deg );
      width:  20px;
  }
  
  .me {
      background-color: #087FFE;
      margin-left: 18px;
      margin-right:60px;
  }
  
  .me::before {
      box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
      left: -9px;
      background-color: #087FFE;
  }
  
  .you {
    background-color: #e60000;
      margin-left: 60px;
      margin-right:18px;
  }
  
  .you::before {
      box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
      right: -9px;
      background-color: #e60000;
  }
  
  .img-circle {
    border-radius: 42%;
    height:42px;
    width:42px;
  }

  .chats {
    position: relative;
    height: 100%;
  }

  .chat-list {
    height: calc(100% - 3rem);
    overflow-y: scroll;
    padding: 1rem;
    padding-bottom: 0.5rem;
  }

  .new-message {
    position:relative;
    top: 2vh;
    background-color: #e60000;
    height: 3rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .new-message-input {
    width: 99%;
    height: 80%;
    font-size: 1.5rem;
  }