.header {
  font-family: "source sans pro light";
  display: flex;
  background-image: linear-gradient(120deg,
      #e60000 0%,
      #de004a 100%) !important;
}

.header p {
  margin: unset;
  padding: 0.5rem 0 0 0.5rem;
  color: white;
}

.header a {
  display: block;
  margin: 1rem;
  margin-left: auto;
  max-width: 30vw;
  max-height: 20vh;
}

body {
  background-color: white !important;
}

.error {
  color: #de004a 100% !important;
  height: 100%;
  display: block;
  margin: 1rem;
  margin-right: auto;
  width: 50%;
  border: 3px solid #de004a;
  padding: 70px 0;
  transform: translate(50%, 0%);
  text-align: center;
}

.loading {
  color: #de004a 100% !important;
  display: block;
  transform: translate(40%, 40%);
  text-align: center;
}