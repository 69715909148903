:root {
    /* To Do: adjust to colors from mockup */
    --primary-red: rgb(230, 0, 0);
    --secondary-red: rgb(222, 0, 75);
}

/*#bottom {
    position: absolute;
    bottom: 0;
    right: 0%;
    left: 100%;
}*/

#grid-dbg:hover {
    background-color: red;
}

#test {
    text-align: -webkit-center;
    color: aqua;
}

#container_left {
    /* background-color as fallback background color for browser that do not support color gradients */
    background-color: var(--primary-red);
    /* color gradients for legacy browser */
    background-image: -webkit-linear-gradient(var(--primary-red), var(--secondary-red));
    background-image: -moz-linear-gradient(var(--primary-red), var(--secondary-red));
    background-image: -ms-linear-gradient(var(--primary-red), var(--secondary-red));
    background-image: -o-linear-gradient(var(--primary-red), var(--secondary-red));
    /* color gradients for modern browser */
    background-image: linear-gradient(to right bottom, var(--primary-red), var(--secondary-red));
}